import { get } from 'lodash';

export const formatted_number = (obj, default_number = '$0') => (
    get(obj, 'formatted', default_number)
);

export const format_money = (number) => {
    const castedNumber = Number(number);
    const parts = (+castedNumber).toFixed(2).split('.');
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
};

export const unformatted_amount = (obj, default_number = '0') => (
    get(obj, 'amount', default_number)
);

export const special_formatted_number = (obj) => {
    const amount = unformatted_amount(obj);

    if (!parseInt(amount)) {
        return 'FREE';
    }

    return formatted_number(obj);
};

// convert a string number with currency symbol and commas to an int and if not a number then return the default
export const numberize_int = (number, default_number = 0) => {
    const new_number = parseInt((number + '').replace(/\D/g, ''));

    return isNaN(new_number) ?
        default_number :
        new_number;
};

export const do_format_number = (number, default_number = '0', currency = '') => (
    `${currency}${(numberize_int(number, default_number) + '').replace(/\d(?=(\d{3})+$)/g, '$&,')}`
);

export const pad = (num, size) => {
    if (num < 0) {
        return `-${pad(Math.abs(num), size)}`;
    }

    const s = '000000000' + num;
    return s.substr(s.length - size);
};

export const format_percentage = (value, toFixed = 2) => {
    if (!(+value)) {
        return '0%';
    } 
    return parseFloat((+value).toFixed(toFixed)) + '%';
}

export const generateRange = (end, start = 0, increment = 1) => {
    const isEndDef = typeof end !== 'undefined';
    end = isEndDef ? end : start;
    start = isEndDef ? start : 0;

    if (typeof increment === 'undefined') {
        increment = Math.sign(end - start);
    }

    const length = Math.abs((end - start) / (increment || 1));

    const { result } = Array.from({ length }).reduce(
        ({ result, current }) => ({
            result: [...result, current],
            current: current + increment,
        }),
        { current: start, result: [] },
    );

    return result;
};

export const truncateToOneDecimal = (num) => {
    if (num === undefined || num === null) return '0';
    const strNum = num.toString();
    const decimalIndex = strNum.indexOf('.');
    if (decimalIndex === -1) return strNum;
    return strNum.slice(0, decimalIndex + 2);
};